<template>
  <footer>
      <div class="container">
          <div class="left">
              <div class="col-1">
                  <router-link class="header" :to="{name: 'Home'}">Aimsoft</router-link>
                  <ul>
                      <li>

                      </li>
                  </ul>
              </div>
               <!-- <div class="col-2">
                  <router-link class="link" :to="{name: 'home'}">Hem</router-link>
                   <router-link class="link" :to="{name: 'reports'}">Rapporter</router-link>
                   <router-link class="link" :to="{name: 'login'}">Logga in</router-link>
                  <ul>
                      <li>
                          
                      </li>
                  </ul>
              </div> -->
          </div>
          <div class="right">
              <p>Copyright 2022 All rights reserved</p>
          </div>
      </div>
  </footer>
</template>

<script>
export default {
    name: "footer-vue"
}
</script>

<style lang="scss" scoped>
    footer {
        margin-top: auto;
        padding: 25px 25px;
        background-color: #303030;
        .container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            z-index: 100;
            @media (min-width: 800px) {
                flex-direction: row;
                gap: 0px;
            }
            > div {
                display: flex;
                flex: 1;
            }

            .left {
                gap: 32px;
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                 @media (min-width: 800px) {
                flex-direction: row;
                 align-items: initial;
                gap: 0px;
                }
            }

            .header {
                text-align: center;
                font-size: 24px;
                color: white;
                text-decoration: none;
                font-weight: 600;
            }
            ul {
                gap: 16px;
                list-style: none;
                display: flex;
            }

            .col-1,
            .col-2 {
                gap: 32px;
                display: flex;
                flex: 1;
                 @media (min-width: 800px) {
                gap: 0px;
                }
            }

            .col-1 {
                flex-direction: column;

                h2 {
                    text-align: center;
                     @media (min-width: 800px) {
                         text-align: initial;
                    }   
                }

                ul {
                    margin-top: auto;
                    li {
                        display: flex;
                        align-items: center;
                        .svg-icon {
                            width: 24px;
                            height: auto;
                            color: white;
                        }
                    }
                }
            }

            .col-2 {
                ul {
                    height: 100%;
                    justify-content: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                     @media (min-width: 800px) {
                        flex-direction: column;
                    } 
                    .link {
                        font-size: 16px;
                        font-weight: 500;
                        color: white;
                        text-decoration: none;
                    }  
                }
            }

            .right {
                gap: 32px;
                color: white;
                align-items: center;
                flex-direction: column;
                 @media (min-width: 800px) {
                 align-items: flex-end;
                 gap: 0;
                } 
            }

            p {
                margin-top: auto;
            }
        }
}
</style>