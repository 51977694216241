<template>
   <!-- <div class="hero"> 
        <button class="banner"><router-link class="router-link" :to="{name: 'Login'}">
          <h1 class="banner-title">Aimsoft tidrapportering!</h1>
        </router-link>
        </button>
      </div> -->
      <div class="home">
        <Welcome :post="welcomeScreen" />
      </div>
</template>

<script>
import Welcome from '../components/Welcome.vue'
export default {
   name: "Home",
  components: {
    Welcome
  },
  data() {
   return  {
     welcomeScreen: {
      title: "Aimsoft Tidrapportering",
      intro: "Detta är bara en första upplaga för att testa funktionaliteten i autentisering med Firebase!",
      welcomeScreen: true,
      photo: "time"
      }
    } 
  },
};
</script>

<style lang="scss" scoped>
/* --------------- Hero ---------------- */
.hero {
  min-height: calc(100vh - 60px);
  background: url('../assets/blogCards/stock-3.jpg')  center/cover no-repeat;  
  position: relative;
}
.banner {
  text-align: center;
  position: absolute;
  top: 90px;
  left: 20%;
  right: 20%;
 // background: rgba(255, 255, 255, 0.8);
  background: rgba(#29bbff,  0.8);
  border-radius: 10px;
  border: none;
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 2rem;
  .router-link {
      color: rgb(243, 234, 234);
      text-decoration: none !important;
   }
}
.banner-title {
  font-size: 3rem;
  text-transform: uppercase;
 // letter-spacing: $mainSpacing;
  margin-bottom: 1rem;
  font-weight: 800;
}
.banner-btn {
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-size: 1rem;
 // background: $primaryColor;
 // color: $mainWhite;
  font-weight: 700;
  border: 4px solid white;
 // transition: $mainTransition;
  /* cursor: pointer;
  -webkit-transition: $mainTransition;
  -moz-transition: $mainTransition;
  -ms-transition: $mainTransition;
  -o-transition: $mainTransition;*/
} 
.banner-btn:hover {
  background: transparent;
 // color: $primaryColor;
}
/* ---------------Media querys ---------------- */

@media only screen and (max-width: 750px) {
  .hero{
    align-items: center;
  }
  .banner {
    left: 0px;
     right: 0px;
  }
  .banner-title {
    font-size: 1.4rem;
    text-transform: uppercase;
   // letter-spacing: $mainSpacing;
    margin-bottom: 1rem;
    font-weight: 900;
  }

  .section-title h2{
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .products{
    padding: 1.5rem;
  }

  .shopping-btn, .info-btn {
    font-size: 1rem;
  }
  .products-center {
    width: 70vw;
  }
}
  
</style>
