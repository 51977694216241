<template>
  <div class="admin">
      <div class="container">
          <h2>Administration</h2>
          <div class="admin-info">
              <h2>Lägg till admin</h2>
               
               <div class="input">
                <Loading v-if="loading"/>
                  <label for="email">Email</label>
                  <input type="text" placeholder="Befintlig mail för att göra admin" id="addAdmin" v-model="adminEmail"/>                  
              </div>
              <span>{{this.functionMessage}}</span>
              <button @click="addAdmin" class="button">Skicka</button>
          </div>
           <div class="admin-info">
              <h2>Ta bort admin</h2>
               
               <div class="input">
                <Loading v-if="loading"/>
                  <label for="email">Email</label>
                  <input type="text" placeholder="Befintlig mail för att ta bort admin" id="removeAdmin" v-model="adminEmailRemove"/>                  
              </div>
              <span>{{this.functionMessage}}</span>
              <button @click="removeAdmin" class="button">Skicka</button>
          </div>
          
      </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import Loading from '../components/Loading';
export default {
    name: "Admin",
    components: {
        Loading
    },
    data() {
        return {
            adminEmail: "",
            adminEmailRemove: "",
            functionMessage: null,
            loading: null
        }
    },
    methods: {
        async addAdmin() {
            this.loading = true;
            const addAdmin = firebase.functions().httpsCallable('addAdminRole');
            const result = await addAdmin({email: this.adminEmail});
            this.functionMessage = result.data.message;
            this.loading = false;
        },
         async removeAdmin() {
            this.loading = true;
            const removeAdmin = firebase.functions().httpsCallable('removeAdminRole');
            const result = await removeAdmin({email: this.adminEmailRemove});
            this.functionMessage = result.data.message;
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.admin {
    .container {
        max-width: 1000px;
        padding: 60px 25px;
        h2 {
            text-align: center;
            margin-bottom: 16px;
            font-weight: 300;
            font-size: 32px; 
        }
        .admin-info {
            border-radius: 8px;
            box-shadow: 2px 4px 6px -1px rgba(0,0,0,0.1), 2px 2px 4px -1px rgba(0,0,0,0.1);
            padding: 16px;
            background-color: rgb(185, 190, 190);
            display: flex;
            flex-direction: column;
            max-width: 600px;
            margin: 32px auto;
            span {
                font-size: 14px;
            }
            .input {
                margin: 16px 0;
                label {
                    font-size: 14px;
                    display: block;
                    padding-bottom: 6px;
                }
                input {
                    width: 100%;
                    border: none;
                    background-color: #f2f7f6;
                    padding: 8px;
                    height: 50px;
                    border-radius: 6px;
                    @media (min-width: 900px) {

                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            button {
                width: 100%;
                transition: all .5s ease;
                cursor: pointer;
                margin-top: 10px;
                padding: 12px 24px;
                background-color: #303030;
                color: #fff;
                border-radius: 20px;
                border: none;
                text-transform: uppercase;
                @media (max-width: 768px) {
                width: 100%;
                } 
            }
        }
    }
}
</style>