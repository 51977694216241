<template>
    <div class="hero">
        <div class="banner">
          <h1 class="banner-title"></h1>
          <!-- <Link to="/products"> -->
            
            <button class="banner-btn"><router-link class="router-link" :to="{name: 'ReportTime'}">Rapportera tid</router-link></button>
          <!-- </Link> -->
        </div>
      </div> 
</template>

<script>
export default {
  name: "ReportTime",
  components: {},
};
</script>

<style lang="scss" scoped>
/* --------------- Hero ---------------- */
.hero {
  min-height: calc(100vh - 60px);
  background: url('../assets/reporting.jpg')  center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner {
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  display: inline-block;
  padding: 2rem;
}
.banner-title {
  font-size: 3.4rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
  margin-bottom: 1rem;
  font-weight: 800;
}
.banner-btn {
  padding: 1rem 2rem;
  
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-size: 1rem;
  background: #fdc84b;
  color: white;
  font-weight: 700;
  border: 4px solid white;
  transition: all 0.4s linear;
  cursor: pointer;
  /* -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear; */
   .router-link {
      color: rgb(153, 22, 22);
      text-decoration: none !important;
   }
} 
.banner-btn:hover {
  background: transparent;
  color: #5bac26;
}
/* ---------------Media querys ---------------- */

@media only screen and (max-width: 750px) {
  .hero{
    align-items: center;
  }
  .banner-title {
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
    font-weight: 900;
  }

  .section-title h2{
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .products{
    padding: 1.5rem;
  }

  .shopping-btn, .info-btn {
    font-size: 1rem;
  }
  .products-center {
    width: 70vw;
  }
}
  
</style>
