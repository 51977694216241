import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Profile from '../views/Profile.vue';
import Admin from '../views/Admin.vue';
import ReportTime from '../views/ReportTime.vue'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      requiresAuth: false
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Register",
      requiresAuth: false
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "ForgotPassword",
      requiresAuth: false
    }
  },
  {
    path: "/report-time",
    name: "ReportTime",
    component: ReportTime,
    meta: {
      title: "ReportTime",
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "Profile",
      requiresAuth: true
    }
  }, {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "Admin",
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  // { 
  //   path: '/:pathMatch(.*)*', 
  //   name: "Login",
  //   component: Login,
  //   meta: {
  //     title: "Login",
  //     requiresAuth: false
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async( to, from, next) => {
 // let user = this.$store.state.user;
  let user = firebase.auth().currentUser;
  let admin = null;
  if(user) {
    let token = await user.getIdTokenResult();
    admin = token.claims.admin;
  }
  if(to.matched.some((res) => res.meta.requiresAuth)) {
    if(user) {
      if(to.matched.some((res) => res.meta.requiresAdmin)) {
        if(admin) {
          return next();
        }
        return next({name: "ReportTime"})
      }
      return next();
    }
    return next({name: "Home"})
  }
  return next();
});

export default router;
