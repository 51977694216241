<template>
  <div class="form-wrap">
      <form class="login">
        <div class="logo">
            <router-link v-if="!user" class="header" :to="{name: 'Home'}">
                   <a><img src="../assets/logo-180x100.png" alt="Aimsoft"></a>
            </router-link>
        </div>
        
          <p class="login-register">
              Har du inget konto?
              <router-link class="router-link" :to="{name: 'Register'}">Registrera</router-link>
          </p>
          <h2>Aimsoft tidrapport</h2>
          <div class="inputs">
              <div class="input">
                  <input type="text" placeholder="email" v-model="email"/>
                  <email class="icon" />
              </div>
               <div class="input">
                  <input type="password" placeholder="lösenord" v-model="password"/>
                  <password class="icon" />
              </div>
              
           <div v-show="error" class="error">{{ this.errorMessage }}</div>
              <button @click.prevent="logIn">Logga in</button>
          </div>
          <router-link class="forgot-password" :to="{name: 'ForgotPassword'}">Glömt lösenord?</router-link>
          
      </form>
  </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg';
import password from '../assets/Icons/lock-alt-solid.svg';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

export default {
    name: "Login",
  components: {
      email,
      password
  },
  data() {
      return {
        email: "",
        password: "",
        error: null,
        errorMessage: "",
        user: null
      }
  },
  methods: {
      logIn() {
          firebase.auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then(response => {
        console.log(response);
        if(response.user) {
          localStorage.setItem('user', JSON.stringify(response.user));
          const user = firebase.auth().currentUser.uid;
          console.log('Current USer from firestore', user);
          this.$router.push({name: 'ReportTime'});
          this.error = false;
          this.errorMessage = "";
        }
      })
      .catch((error) => {
            this.error = true;
          this.errorMessage = error.message;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.form-wrap {
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 900px) {
        width: 100%;
    }
    .logo {
        padding: 20px 0;
    }
    .login-register {
        margin-bottom: 32px;
        .router-link {
            color: black;
        }
    }
    form {
        padding: 0 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        h2 {
            text-align: center;
            font-size: 32px;
            color: #303030;
            margin-bottom: 40px;
            @media (max-width: 768px) {
            font-size: 18px;
            } 
        }
        .inputs {
            width: 100%;
            max-width: 350px;
            .input {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                input {
                    width: 100%;
                    border: none;
                    background: #f2f7f6;
                    padding: 4px 4px 4px 30px;
                    border-radius: 10px;
                    height: 50px;
                    &:focus {
                        outline: none;
                    }
                }
                .icon {
                    width: 12px;
                    position: absolute;
                    left: 6px;
                }
            }
        }
        .forgot-password {
            text-decoration: none;
            color: black;
            cursor: pointer;
            font-size: 14px;
            margin: 16px 0 32px;
            border-bottom: 1px solid transparent;
            transition: 0.5s ease all;
            &:hover {
                border-color: #303030;
            }
        }
        button {
            width: 100%;
            transition: all .5s ease;
            cursor: pointer;
            margin-top: 5px;
            padding: 12px 24px;
            background-color: #303030;
            color: #fff;
            border-radius: 20px;
            border: none;
            text-transform: uppercase;
             @media (max-width: 768px) {
            width: 100%;
            } 
        }
    }
}
</style>