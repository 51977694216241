<template>
  <div class="banner-wrapper">
    <div class="banner-content">
        <div>
            <h2 v-if="post.welcomeScreen">{{post.title}}</h2>
            <p  v-if="post.welcomeScreen">{{post.intro}}</p>
             <router-link class="link" v-if="post.welcomeScreen" :to="{name: 'Login'}">
                 Inloggning <Arrow class="arrow"/>
            </router-link> 
            <!-- <Arrow class="arrow arrow-light"/> -->
        </div>       
    </div>
     <div class="banner-photo">
        <img v-if="post.welcomeScreen" :src="require(`../assets/${post.photo}.jpg`)" alt="">
        </div>
  </div>
</template>

<script>
import Arrow from '../assets/Icons/arrow-right-light.svg'
export default {
    name: "Welcome",
    props: ["post"],
    components: {
       Arrow
    }

}
</script>

<style lang="scss" scoped>
.banner-wrapper {
    display: flex;
    flex-direction: column;
     box-shadow: 0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.1);
    // padding: 4px;
     @media(min-width: 700px) {
         min-height: 890px;
         max-height: 890px;
         flex-direction: row;
     }
     .banner-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 2;
        order: 2;
        background-color: whitesmoke;
        @media(min-width: 700px) {
         order: 1;
        }
        div {
           // max-width: 350px;
            padding: 16px 24px;
             @media(min-width: 700px) {
                padding: 0 14px;
            }
        }
        h2 {
            font-size: 24px;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 20px;
             @media(min-width: 700px) {
                font-size: 40px;
            }
        }
        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.7;
        }
        .link {
            display: inline-flex;
            align-items: center;
            margin-top: 32px;
            padding-bottom: 4px;
            border-bottom: 1px solid transparent;
            transition: .5s ease-in all;
            &:hover {
                border-bottom-color: #303030;
            }
        }
        .link-light {
            &:hover {
                border-bottom-color: white;
            }
        }
    }
    .banner-photo {
        order: 1;
        flex: 3;
        box-shadow: 0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.1);
        img {
           display: block;
           width: 100%;
           height: 100%;
           object-fit: cover; 
        }
    }
}
</style>