<template>
  <header>
      <nav class="container">
          <div class="branding">
               <router-link v-if="user" class="header" :to="{name: 'ReportTime'}">
                   <a><img src="../assets/logo-180x100.png" alt="Aimsoft"></a>
                </router-link>
                <router-link v-if="!user" class="header" :to="{name: 'Home'}">
                   <a><img src="../assets/logo-180x100.png" alt="Aimsoft"></a>
                </router-link>
          </div>
          <div class="nav-links">
              <ul v-show="!mobile">
                <router-link v-if="admin" class="link" to="#">Rapporter</router-link>
                <router-link v-if="user" class="link" :to="{name: 'ReportTime'}">Skapa rapport</router-link>
                <router-link v-if="!user" class="link" :to="{name: 'Login'}">Logga in</router-link>
              </ul>
              <div v-if="user" @click="toggleProfileMenu" class="profile" ref="profile">
                  <span>{{this.$store.state.profileInitials}}</span>
                  <div v-show="profileMenu" class="profile-menu">
                      <div class="info">
                          <p class="initials">{{this.$store.state.profileInitials}}</p>
                          <div class="right">
                              <p>{{this.$store.state.profileFirstName}} {{this.$store.state.profileLastName}}</p>
                               <p>{{this.$store.state.profileUsername}} </p>
                                <p>{{this.$store.state.profileEmail}} </p>
                          </div>
                      </div>
                      <div class="options">
                          <div class="option">
                               <router-link class="option" :to="{name: 'Profile'}">
                                  <userIcon class="icon" /> 
                                  <p>Profil</p>
                               </router-link>
                          </div>
                           <div v-if="admin" class="option">
                               <router-link class="option" :to="{name: 'Admin'}">
                                  <adminIcon class="icon" /> 
                                  <p>Admin</p>
                               </router-link>
                          </div>
                           <div @click="signOut" class="option">                              
                                <signOutIcon class="icon" /> 
                                <p>Logga ut</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </nav>
      <menuIcon @click="toggleMobileNav" class="menu-icon" v-show="mobile" />
      <transition name="mobile-nav">
        <ul class="mobile-nav" v-show="mobileNav">        
            <router-link v-if="admin" class="link" to="#">Rapporter</router-link>
            <router-link v-if="user" class="link" :to="{name: 'ReportTime'}">Skapa rapport</router-link>
            <router-link v-if="!user" class="link" :to="{name: 'Login'}">Logga in</router-link>
        </ul>
      </transition >
  </header>
</template>

<script>
import menuIcon from '../assets/Icons/bars-regular.svg';
import userIcon from '../assets/Icons/user-alt-light.svg';
import adminIcon from '../assets/Icons/user-crown-light.svg';
import signOutIcon from '../assets/Icons/sign-out-alt-regular.svg';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

export default {
    name: 'navigation',
    components: {
       menuIcon,
       userIcon,
       adminIcon,
       signOutIcon
    },
    data() {
        return {
            profileMenu: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null
        };
    },
    created() {
        this.checkRoute();
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    mounted() {},
    methods: {
        checkScreen() {
          this.windowWidth = window.innerWidth;  
          if(this.windowWidth <= 750) {
              this.mobile = true;
              return;
          }
          this.mobile = false;
          this.mobileNav = false;
          return;
        },
        toggleMobileNav() {
           this.mobileNav = !this.mobileNav; 
        },
         checkRoute() {
            if(this.$route.name !== "/") {
                this.mobileNav = false;
                this.profileMenu = false;
                return;
            }
        },
        toggleProfileMenu(e) {
            if(e.target === this.$refs.profile) {
                this.profileMenu = !this.profileMenu;
            }
            
        },
        signOut() {            
            firebase.auth().signOut();  
            this.$router.push({ name: 'Login'} )          
            window.location.reload();
            
        }

    },
     watch: {
        $route() {
        this.checkRoute();
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        admin() {
            return this.$store.state.profileAdmin;
        }
    }
}
</script>

<style lang="scss" scoped>
    header {
        background-color:#fff;
        padding: 0 25px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.6);
        z-index: 99;

    .link {
        font-weight:500;
        padding: 0 8px;
        transition: .3s color ease;

        &:hover {
            color: #35cbe6;
        }
    }

    nav {
        display: flex;
        padding: 15px 0;

        .branding {
            display: flex;
            align-items: center;
        }

    .nav-links {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;

            ul {
                margin-right: 32px;

                .link {
                    margin-right: 32px;
                }
                    .link:last-child{
                    margin-right: 0;
                }
            }

            span {
                pointer-events: none;
            }
            .profile {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                color: white;
                background-color: #303030;
                .profile-menu {
                    position: absolute;
                    top: 60px;
                    right: 0;
                    width: 250px;
                    background-color: #303030;
                    border-radius: 6px;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                    .info {
                        display: flex;
                        align-items: center;
                        padding: 15px;
                        border-bottom: 1px solid white;
                        .initials {
                            position: inherit;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            color:#303030;
                            background-color: white;  
                        }
                        .right {
                            flex: 1;
                            margin-left: 24px;
                             p:nth-child(2) {
                                 font-size: 24px;
                             }

                            p:nth-child(2),
                            p:nth-child(3) {
                                font-size: 12px;
                            }
                        }
                    }
                    .options {
                        padding: 15px;
                        .option {
                           text-decoration: none;
                           color: #fff;
                           display: flex;
                           align-items: center;
                           margin-bottom: 12px;
                           .icon {
                               width: 18px;
                               height: auto;
                           } 
                           p {
                              font-size: 14px;
                              margin-left: 12px; 
                           }
                        }
                        .option:last-child {
                            align-items: flex-end;
                            justify-content: end;
                            margin-bottom: 0px;
                        }
                    }
                }
                @media (max-width: 768px) {
                    left: -40px;
                }     
                
            }
        }
        
    }

    .menu-icon {
        cursor: pointer;
        position: absolute;
        top: 32px;
        right: 25px;
        height: 25px;
        width: auto;
    }

    .mobile-nav {
        padding: 20px;
        width: 70%;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        background-color: #303030;
        top: 0;
        left: 0;

        .link {
            padding: 15px 0;
            color: white;
        }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
        transition: all 1s ease;
    }

     .mobile-nav-enter {
         transform: translateX(-250px);
     }

     .mobile-nav-enter-to {
        transform: translateX(0);
     }

     .mobile-nav-leave-to {
         transform: translateX(-250px);
     }
}
</style>