<template>
  <div class="reset-password">
      <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
      <Loading v-if="loading"/>
      <div class="form-wrap">
          <form class="reset">
             <div class="logo">
                <router-link v-if="!user" class="header" :to="{name: 'Home'}">
                    <a><img src="../assets/logo-180x100.png" alt="Aimsoft"></a>
                </router-link>
            </div>
              <h2>Återställa lösenord</h2>
              <p>Skriv in email för att återställa.</p>
               <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="email" v-model="email"/>
                    <email class="icon" />
                </div>
              <button @click.prevent="resetPassword">Skicka och återställ</button>
              <p class="login-register">
                Inte återställa?
              <router-link class="router-link" :to="{name: 'Login'}">Logga in</router-link>
            </p>
          </div>
          </form>
      </div>
  </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg';
import Modal from '../components/Modal';
import Loading from '../components/Loading';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

export default {
    name: "ForgotPassword",
    components: {
      email,
      Modal,
      Loading
  },
  methods: {
      resetPassword() {
          this.loading = true;
          firebase.auth().sendPasswordResetEmail(this.email)
          .then(() => {
              this.modalMessage = "Du kommer att få ett mail med informationen för att återställa lösenordet.";
              this.loading = false;
              this.modalActive = true;
          })
          .catch((error) => {
              this.modalMessage = error.message;
              this.loading = false;
              this.modalActive = true;
          })
      },
      closeModal() {
         this.modalActive = !this.modalActive; 
         this.email = "";
      }
  },
  data() {
      return {
          email: null,
          modalActive: false,
          modalMessage: "",
          loading: null,
          user: null
      }
  }
}
</script>

<style lang="scss" scoped>
.reset-password {
    position: relative;
    .form-wrap {
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 900px) {
        width: 100%;
    }
    .logo {
        padding: 20px 0;
    }

    .login-register {
        margin-bottom: 32px;
        .router-link {
            color: black;
        }
    }

    form {
        padding: 0 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        h2 {
            text-align: center;
            font-size: 32px;
            color: #303030;
            margin-bottom: 40px;
            @media (max-width: 768px) {
            font-size: 18px;
            } 
        }
         p {
            margin-top: 20px;
            display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       // flex: 1;
        }
        .inputs {
            width: 100%;
            max-width: 350px;
            .input {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                input {
                    width: 100%;
                    border: none;
                    background: #f2f7f6;
                    padding: 4px 4px 4px 30px;
                    border-radius: 10px;
                    height: 50px;
                    &:focus {
                        outline: none;
                    }
                }
                .icon {
                    width: 12px;
                    position: absolute;
                    left: 6px;
                }
            }
        }
        button {
            width: 100%;
            transition: all .5s ease;
            cursor: pointer;
            margin-top: 24px;
            padding: 12px 24px;
            background-color: #303030;
            color: #fff;
            border-radius: 20px;
            border: none;
            text-transform: uppercase;
        }
    }
}
}
</style>