<template>
  <div class="app-wrapper">
    <div class="app">
      <Navigation v-if="navigation"/>
      <router-view />
       <Footer v-if="navigation"/>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'

export default {
  name: "app",
  components: { Navigation, Footer },
  data() {
    return {
      navigation: null,
      user: null
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.$store.commit("updateUser", user);
      if(user) {
        const token = await user.getIdTokenResult();
         console.log(this.$store.state.profileEmail);
         console.log('USER => ', token.claims);
        this.$store.dispatch("getCurrentUser", user);
       
      }
    })
    this.checkRoute();
    // console.log(firebase.auth().currentUser);
  },
  mounted() {},
  methods: {
    checkRoute() {
      if(this.$route.name === "Login" || this.$route.name === "Register" || this.$route.name === "ForgotPassword") {
        this.navigation = false;
         return;
      }
        this.navigation = true;
    }
  },
  watch: {
    $route() {
      this.checkRoute();
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}

.error {
  text-align: center;
  font-size: 12px;
  color: red;
}

.arrow {
  margin-left: 8px;
  width: 12px;
  path {
      fill: #000;
  }
    }
</style>
