<template>
  <div class="profile">
      <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
      <div class="container">
          <h2>Konto uppgifter</h2>
          <div class="profile-info">
              <div class="initials">{{$store.state.profileInitials}}</div>
              <div v-if="admin" class="admin-badge">
                  <adminIcon class="icon" />
                  <span>admin</span>
              </div>
               <div class="input">
                   <label for="firstName">Förnamn</label>
                   <input type="text"  v-model="firstName"/>                  
              </div>
                <div class="input">
                    <label for="lastName">Efternamn</label>
                    <input type="text"  v-model="lastName"/>               
              </div>
                <div class="input">
                    <label for="userName">Användarnamn</label>
                    <input type="text"  v-model="username"/>                  
              </div>
              <div class="input">
                  <label for="email">Email</label>
                  <input type="text" disabled v-model="email"/>                  
              </div>
               <!-- <div class="input">
                  <input type="password" placeholder="lösenord" v-model="password"/>
                  <password class="icon" />
              </div> -->
              <!-- <div v-show="error" class="error">{{ this.errorMessage }}</div> -->
              <button @click.prevent="updateProfile">spara ändringar</button>
          </div>
      </div>
  </div>
</template>

<script>
import Modal from '../components/Modal.vue'
import adminIcon from '../assets/Icons/user-crown-light.svg'
export default {
    name: "Profile",
    components: {
        Modal,
        adminIcon
    },
    data() {
        return {
            modalMessage: "Ändringarna sparades ok!",
            modalActive: null
        }
    },
    methods: {
        closeModal() {
            this.modalActive = !this.modalActive;
        },
        updateProfile() {
            this.$store.dispatch("updateUserSettings");
            this.closeModal();
        }
    },
    computed: {
        firstName: {
            get() {
                return this.$store.state.profileFirstName;
            },
            set(payload) {
                this.$store.commit("changeFirstName", payload);
            }
        },
         lastName: {
            get() {
                return this.$store.state.profileLastName;
            },
            set(payload) {
                this.$store.commit("changeLastName", payload);
            }
        },
         username: {
            get() {
                return this.$store.state.profileUsername;
            },
            set(payload) {
                this.$store.commit("changeUsername", payload);
            }
        },
         email() {
           return this.$store.state.profileEmail;
        },
         admin() {
            return this.$store.state.profileAdmin;
        }
    }

}
</script>

<style lang="scss" scoped>
.profile {
    .container {
        max-width: 1000px;
        padding: 30px 16px;
        h2 {
            text-align: center;
            margin-bottom: 16px;
            font-weight: 300;
            font-size: 32px;
        }
        .profile-info {
            border-radius: 8px;
            box-shadow: 2px 4px 6px -1px rgba(0,0,0,0.1), 2px 2px 4px -1px rgba(0,0,0,0.1);
            padding: 16px;
            background-color: rgb(185, 190, 190);
            display: flex;
            flex-direction: column;
            max-width: 600px;
            margin: 32px auto;
            .initials {
                position: initial;
                width: 80px;
                height: 80px;
                font-size: 32px;
                background-color: #303030;
                color: white;
                display: flex;
                align-self: center;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
            .admin-badge {
                display: flex;
                align-self: center;
                color: #fff;
                font-size: 14px;
                padding: 8px 24px;
                border-radius: 8px;
                background-color: #303030;
                margin: 16px auto;
                text-align: center;
                text-transform: capitalize;
                .icon {
                    width: 12px;
                    height: auto;
                    margin-right: 8px;
                }
            }
            .input {
                margin: 12px 0;
               // margin: 10px 0;
                /* position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px; */
                label {
                    font-size: 14px;
                    display: block;
                    padding-bottom: 6px;
                }
                input {
                    width: 100%;
                    border: none;
                    background: #f2f7f6;
                    padding: 8px;
                    border-radius: 6px;
                    height: 40px;
                    &:focus {
                        outline: none;
                    }
                }
            }
             button {
            width: 100%;
            transition: all .5s ease;
            cursor: pointer;
            margin-top: 24px;
            padding: 12px 24px;
            background-color: #303030;
            color: #fff;
            border-radius: 20px;
            border: none;
            text-transform: uppercase;
             @media (max-width: 768px) {
            width: 100%;
            } 
        }
        }
    }
}
</style>