import firebase from "firebase/compat/app";
// import "firebase/firebase-firestore";
import "firebase/compat/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyDRwlUCUyPDbGAbw4zeV5ngMg7yCI0puJg",
    authDomain: "vuetimereport.firebaseapp.com",
    projectId: "vuetimereport",
    storageBucket: "vuetimereport.appspot.com",
    messagingSenderId: "1070174624559",
    appId: "1:1070174624559:web:0ea49cffba8d4a90ba1fda",
    measurementId: "G-H6QZPR364F"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

export { timeStamp };
export default firebaseApp.firestore();