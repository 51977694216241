<template>
  <div class="form-wrap">
      <form class="register">
         <div class="logo">
            <router-link v-if="!user" class="header" :to="{name: 'Home'}">
                   <a><img src="../assets/logo-180x100.png" alt="Aimsoft"></a>
            </router-link>
        </div>
          <p class="login-register">
              Har du konto?
              <router-link class="router-link" :to="{name: 'Login'}">Logga in</router-link>
          </p>
          <h2>Skapa inloggning för tidrapportering</h2>
            <div class="inputs">
                <div class="input">
                  <input type="text" placeholder="förnamn" v-model="firstName"/>
                  <user class="icon" />
              </div>
                <div class="input">
                  <input type="text" placeholder="efternamn" v-model="lastName"/>
                  <user class="icon" />
              </div>
                <div class="input">
                  <input type="text" placeholder="användarnamn" v-model="username"/>
                  <user class="icon" />
              </div>
              <div class="input">
                  <input type="text" placeholder="email" v-model="email"/>
                  <email class="icon" />
              </div>
               <div class="input">
                  <input type="password" placeholder="lösenord" v-model="password"/>
                  <password class="icon" />
              </div>
              <div v-show="error" class="error">{{ this.errorMessage }}</div>
              <button @click.prevent="register">Registrera</button>
          </div>
      </form>
  </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg'
import password from '../assets/Icons/lock-alt-solid.svg'
import user from '../assets/Icons/user-alt-light.svg'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import db from '../firebase/firebaseInit'

export default {
    name: "Register",
  components: {
    email,
    password,
    user
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      error: null,
      errorMessage: "",
      user: null
    }
  },
  methods: {
    async register() {
      if(
        this.email !== "" &&
        this.password !== "" &&
        this.username !== "" &&
        this.firstName !== "" &&
        this.lastName !== ""
      ) {
        this.error = false;
        this.errorMessage = "";
        const firbaseAuth = await firebase.auth();
        const createUser = await firbaseAuth.createUserWithEmailAndPassword(this.email, this.password);
        const result = await createUser;
         console.log(result);
        const dataBAse = db.collection("users").doc(result.user.uid);
       // console.log(dataBAse);
       await dataBAse.set({
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.username,
        email: this.email,
       });
       this.$router.push({ name: 'ReportTime'} )
        return;
      }
      this.error = true;
      this.errorMessage = "Fyll i alla fälten";
      return;
    },
  }
}
</script>

<style lang="scss" scoped>
.form-wrap {
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    width: 90%;
    @media (min-width: 900px) {
        width: 100%;
    }
     .logo {
        padding: 20px 0;
    }

    .login-register {
        margin-bottom: 2px;
        .router-link {
            color: black;
        }
    }

    form {
        padding: 0 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        h2 {
            text-align: center;
            font-size: 32px;
            color: #303030;
            margin-bottom: 40px;
            @media (max-width: 768px) {
            font-size: 18px;
            } 
        }
        .inputs {
            width: 100%;
            max-width: 350px;
            .input {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                input {
                    width: 100%;
                    border: none;
                    background: #f2f7f6;
                    padding: 4px 4px 4px 30px;
                    border-radius: 10px;
                    height: 50px;
                    &:focus {
                        outline: none;
                    }
                }
                .icon {
                    width: 12px;
                    position: absolute;
                    left: 6px;
                }
            }
        }
        .forgot-password {
            text-decoration: none;
            color: black;
            cursor: pointer;
            font-size: 14px;
            margin: 16px 0 32px;
            border-bottom: 1px solid transparent;
            transition: 0.5s ease all;
            &:hover {
                border-color: #303030;
            }
        }
        button {
            width: 100%;
            transition: all .5s ease;
            cursor: pointer;
            margin-top: 24px;
            padding: 12px 24px;
            background-color: #303030;
            color: #fff;
            border-radius: 20px;
            border: none;
            text-transform: uppercase;
             @media (max-width: 768px) {
            width: 100%;
            } 
        }
    }
}
</style>