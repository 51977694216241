import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import db from '../firebase/firebaseInit'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null
  },
  mutations: {
    updateUser(state, payload) {
      state.user = payload
    },
    setProfileAdmin(state, payload){
      state.profileAdmin = payload;
      console.log('ProfileAdmin', state.profileAdmin)
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id,
      state.profileEmail = doc.data().email,
      state.profileFirstName = doc.data().firstName,
      state.profileLastName = doc.data().lastName,
      state.profileUsername = doc.data().username
    },
    setProfileInitials(state) {
      state.profileInitials = 
        state.profileFirstName.match(/(\b\S)?/g).join("") +
        state.profileLastName.match(/(\b\S)?/g).join("")
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
    changeEmail(state, payload) {
      state.profileEmail = payload;
    },
  },
  actions: {
   async getCurrentUser({ commit }, user) {
     const dataBase = await db.collection('users').doc(firebase.auth().currentUser.uid);
     const dbResults = await dataBase.get();
     commit("setProfileInfo", dbResults);
     commit("setProfileInitials");
     const token = await user.getIdTokenResult();
     let admin = await token.claims.admin;
     if(admin === undefined) {
      admin = false;
     }
     commit("setProfileAdmin", admin);
   },
   async updateUserSettings({commit, state}) {
    const dataBase = await db.collection('users').doc(state.profileId);
    await dataBase.update({
      firstName: state.profileFirstName,
      lastName: state.profileLastName,
      username: state.profileUsername
    });
    commit("setProfileInitials");
   }
  },
  modules: {
  }
})
